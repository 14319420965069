import get from "lodash-es/get";
import { convToLocalDates } from "@letsgoi/tools-front/dist/tf-time";
import {
  showOrder,
  showOrderByOperation,
  changeDeliveryTime,
  indexAvailableDays,
} from "@/common/helpers/api/services";
import { EMPTY_STRING, DATE_TEMPLATE } from "@/common/helpers/constants";
import i18n from "@/common/helpers/i18n";
import { convToTextDates } from "@/common/helpers/utils";
import { requestMap } from "../helpers/request";

import {
  ROUTE_NAME_DELIVERY,
  ROUTE_NAME_DELIVERY_OPERATION,
} from "../helpers/constants";

import {
  RECIEVED_SUCCESS_ORDER,
  RECIEVED_FAILED_ORDER,
  RECIEVED_SUCCESS_MAP,
  RECIEVED_FAILED_MAP,
  SET_PROGRESS,
  RECIEVED_SUCCESS_AVAILABLE_DAYS,
  RECIEVED_FAILED_AVAILABLE_DAYS,
} from "./types";

const state = {
  order: {},
  progress: {
    changingDeliveryTime: false,
  },
  availableDays: [],
  error: EMPTY_STRING,
};

const getters = {
  deliveredDate: (currState) => {
    const date = get(currState, "order.delivery_date_to");
    return date
      ? convToTextDates(date, { locale: i18n.global.locale })
      : EMPTY_STRING;
  },
};

const actions = {
  doSelectRoute({ dispatch }, route) {
    switch (route.name) {
      case ROUTE_NAME_DELIVERY:
        return dispatch("doShowOrder", route.params.reference);
      case ROUTE_NAME_DELIVERY_OPERATION: {
        const clientOrderIds = route.params?.client_order_id;
        const opReference = route.params.operation_reference;

        const clientOrderId = clientOrderIds?.length
          ? clientOrderIds?.join("/")
          : EMPTY_STRING;

        return dispatch("doShowOrderByOperation", {
          opReference,
          clientOrderId,
        });
      }
      default:
        return Promise.resolve("Not found");
    }
  },
  doShowOrder({ commit }, reference) {
    return showOrder(reference)
      .then((order) => {
        commit(RECIEVED_SUCCESS_ORDER, order);
        return order;
      })
      .catch((error) => {
        commit(RECIEVED_FAILED_ORDER, error);
        throw error;
      });
  },
  doShowOrderByOperation({ commit }, { opReference, clientOrderId }) {
    return showOrderByOperation(opReference, clientOrderId)
      .then((order) => {
        commit(RECIEVED_SUCCESS_ORDER, order);
        return order;
      })
      .catch((error) => {
        commit(RECIEVED_FAILED_ORDER, error);
        throw error;
      });
  },
  doIndexMap({ commit }, { coordinates, size, zoom }) {
    requestMap(coordinates, size, zoom)
      .then((response) => commit(RECIEVED_SUCCESS_MAP, response))
      .catch((error) => {
        commit(RECIEVED_FAILED_MAP, error);
        throw error;
      });
  },
  async doChangeDeliveryTime({ commit }, { orderReference, date }) {
    try {
      commit(SET_PROGRESS, { key: "changingDeliveryTime", value: true });
      const response = await changeDeliveryTime({ orderReference, date });
      commit(SET_PROGRESS, { key: "changingDeliveryTime", value: false });
      return response;
    } catch (error) {
      commit(SET_PROGRESS, { key: "changingDeliveryTime", value: false });
      throw error;
    }
  },
  async doIndexAvailableDays({ commit }, reference) {
    try {
      const days = await indexAvailableDays(reference);
      commit(RECIEVED_SUCCESS_AVAILABLE_DAYS, days);
      return days;
    } catch (error) {
      commit(RECIEVED_FAILED_AVAILABLE_DAYS, error);
      throw error;
    }
  },
};

const mutations = {
  [RECIEVED_SUCCESS_ORDER](currState, order) {
    currState.order = {
      ...order,
      ...convToLocalDates(
        order,
        ["delivery_date_from", "delivery_date_to"],
        DATE_TEMPLATE
      ),
      timeline_items: order.timeline_items.map((item) => ({
        ...item,
        ...convToLocalDates(item, ["tracked_at"]),
      })),
    };
  },
  [RECIEVED_FAILED_ORDER](currState, error) {
    currState.error = error;
  },
  [RECIEVED_SUCCESS_MAP](currState, map) {
    currState.order = {
      ...currState.order,
      map,
    };
  },
  [RECIEVED_FAILED_MAP](currState, error) {
    currState.error = error;
  },
  [SET_PROGRESS](currState, { key, value }) {
    currState.progress[key] = value;
  },
  [RECIEVED_SUCCESS_AVAILABLE_DAYS](currState, days) {
    currState.availableDays = days;
  },
  [RECIEVED_FAILED_AVAILABLE_DAYS](currState, error) {
    currState.error = error;
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
